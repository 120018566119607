define("gemini/controllers/app/files/item", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    saveUpdate: "",

    "upload-state": "waiting",
    response: null,

    "file-name": _ember["default"].computed('model', function () {
      return this.get("model.id");
    }),

    actions: {
      save: function save() {
        var _this = this;

        this.set('upload-state', 'upload');

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        model.save().then(function () {
          _this.set('saveUpdate', 'Opgeslagen!');
          _this.set('upload-state', 'ready');
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('app.files');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      }
    },

    responseListener: _ember["default"].observer('response', function () {
      console.log('response', this.get('response'));
      var model = this.get('model');
      var _this = this;
      model.save().then(function () {
        _this.set('saveUpdate', 'Opgeslagen!');
      });
    })
  });
});