define('gemini/controllers/app/contact', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
    exports['default'] = _ember['default'].Controller.extend({
        i18n: _ember['default'].inject.service(),

        sortedModel: _ember['default'].computed.sort('model', 'sorting'),
        sorting: ['createdAtI'],

        columns: [{
            name: "mail",
            label: "E-mail adres",
            primary: true,
            style: _ember['default'].String.htmlSafe('width: 40%')
        }, {
            name: "message",
            label: "Bericht",
            style: _ember['default'].String.htmlSafe('width: 60%')
        }],

        columnsSet: _ember['default'].on('init', function () {
            this.set('columns.0.i18n', this.get('i18n'));
            this.set('columns.0.label', (0, _emberI18n.translationMacro)('contact.message.emailaddress'));
            this.set('columns.1.i18n', this.get('i18n'));
            this.set('columns.1.label', (0, _emberI18n.translationMacro)('contact.message.message'));
        })
    });
});