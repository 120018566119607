define('gemini/controllers/app', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    pageTitle: "Live Megawatt",

    modules: {
      faq: true,
      news: false,
      info: true,
      contact: true,
      files: true,
      push: false,
      users: true,
      logs: true,
      settings: true
    },

    init: function init() {
      this._super.apply(this, arguments);
      var appCtrl = _ember['default'].getOwner(this).lookup('controller:application');
      this.set('appCtrl', appCtrl);
    }
  });
});