define('gemini/controllers/app/faq/add', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        saveUpdate: "",

        actions: {
            save: function save() {
                var item = this.store.createRecord('faq', {
                    question: this.get('question'),
                    active: false,
                    createdAt: new Date(),
                    createdAtI: -new Date().getTime(),
                    updatedAt: new Date(),
                    updatedAtI: -new Date().getTime()
                });

                this.set('question', '');
                this.set('saveUpdate', "Toegevoegd!");

                this.transitionToRoute('app.faq.item', item);

                item.save();
            },
            cancel: function cancel() {
                this.transitionToRoute('app.faq');
            }
        }
    });
});