define('gemini/helpers/get-property', ['exports', 'ember'], function (exports, _ember) {
  exports.getProperty = getProperty;

  function getProperty(args) {
    var obj = args[0];
    var key = args[1].name;
    return obj.get(key);
  }

  exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(getProperty);
});