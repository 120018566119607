define('gemini/components/image-upload', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Component.extend(_ember['default'].Evented, {
    firebaseApp: _ember['default'].inject.service(),
    session: inject.service(),
    file: null,
    "upload-file": null,
    "image-url": null,
    "image-width": null,
    "image-height": null,

    observer1: _ember['default'].observer('image-url', function () {
      // keep, allows for (eq image-url undefined) in .hbs
      console.log(this.get('image-url'));
    }),

    "new-image": null,

    "upload-state": null,
    uploader: null,
    isLoading: false,

    onInit: _ember['default'].on('init', function () {
      var _this = this;
      this.get('session.currentUser').getToken(false).then(function (token) {
        console.log("token", token);
        _this.set("token", token);
        _this.configUploader();
      });
    }),

    configUploader: function configUploader() {
      var _this2 = this;

      this.set('uploader', _emberUploader['default'].Uploader.create({
        url: this.get('url'),
        ajaxSettings: {
          headers: {
            'X-File-Access': 'public',
            'X-Auth-Token': this.get('token')
          }
        }
      }));

      this.get('uploader').on('progress', function (e) {
        _this2.set("progress", e.percent);
      });

      this.get('uploader').on('didError', function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus, errorThrown);
      });
    },

    actions: {
      fileSelected: function fileSelected(element) {
        console.log('set upload-file: ' + element.target.files);

        this.set('upload-file', element.target.files);

        if (element.target.files && element.target.files[0]) {
          var reader = new FileReader();
          var _this = this;
          reader.onload = function (e) {
            _this.set('new-image', e.target.result);
          };

          console.log('FB: ' + _this.get('firebase-end-point'));
          reader.readAsDataURL(element.target.files[0]);

          if (this.get('image-upload-array') !== undefined) {
            this.get('image-upload-array').pushObject(this);
          }
        }
      },
      openFileDialog: function openFileDialog(className) {
        console.log(className);
        _ember['default'].$("input[type='file']." + className).click();
      }
    },

    upload: function upload() {
      var files = this.get('upload-file');
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        console.log("start image-upload promise", files);
        if (!_ember['default'].isEmpty(files)) {
          console.log('start upload');
          _this.get('uploader').upload(files[0]).then(function (value) {
            console.log('set response', value);

            if (!_this.isDestroyed) {
              _this.set('new-image', null);
              _this.set('isLoading', false);
              _this.set('image-url', value["url"]);
              _this.set('upload-file', null);
              _this.set('image-width', value['width']);
              _this.set('image-height', value['height']);
              resolve(value["url"]);
            } else {
              reject();
            }
          }, function (e) {
            console.log('upload error', e);
            reject(e);
          });
        } else {
          resolve();
        }
      });
    },

    // upload: function(){
    //     var _this = this;
    //     return new Ember.RSVP.Promise(function(resolve, reject){
    //
    //
    //       if (!Ember.isEmpty(_this.get('upload-file'))) {
    //           if(!_this.isDestroyed){
    //               _this.set('isLoading', true);
    //           }
    //           let file = _this.get('upload-file');
    //           let meta = {
    //             customMetadata: {
    //               "firebase-end-point" : _this.get('firebase-end-point')
    //             }
    //           };
    //           var uploadTask = storageRef.child('images/' + _this.get("firebase-end-point").replace(/\//g, "-")).put(file[0], meta);
    //
    //
    //           uploadTask.on(storage.TaskEvent.STATE_CHANGED,
    //             (snapshot) => {
    //
    //               if(!_this.isDestroyed) {
    //                 var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //                 console.log('Upload is ' + progress + '% done');
    //                 _this.set('progressText', `Upload is ${Math.round(progress * 100) / 100} % done`);
    //                 _this.set('progress', progress);
    //                 switch (snapshot.state) {
    //                   case storage.TaskState.PAUSED:
    //                     _this.set('status', 'Upload is paused');
    //                     break;
    //                   case storage.TaskState.RUNNING:
    //                     _this.set('status', 'Upload is running');
    //                     break;
    //                 }
    //               }
    //             }, (error) => {
    //               switch (error.code) {
    //                 case 'storage/unauthorized':
    //                   break;
    //                 case 'storage/canceled':
    //                   break;
    //                 case 'storage/unknown':
    //                   break;
    //               }
    //             }, () => {
    //               if(!_this.isDestroyed) {
    //                 console.log(uploadTask.snapshot)
    //                 _this.set('new-image', null);
    //                 _this.set('isLoading', false);
    //                 _this.set('image-url', uploadTask.snapshot.downloadURL);
    //                 _this.set('upload-file', null);
    //                 console.log("storage", uploadTask.snapshot);
    //                 window.uploadTask = uploadTask;
    //                 //             _this.set('image-width', value['width']);
    //                 //             _this.set('image-height', value['height']);
    //                 resolve(uploadTask.snapshot.downloadURL);
    //               }
    //             });
    //         // const uploader = EmberUploader.Uploader.create({
    //         //     url: "https://www.ventolineswindapp.nl/westermeerwind/file-upload.php"
    //         // });
    //         //
    //         // if (!Ember.isEmpty(_this.get('upload-file'))) {
    //         //     if(!_this.isDestroyed){
    //         //         _this.set('isLoading', true);
    //         //     }
    //         //     var files = _this.get('upload-file');
    //         //     uploader.upload(files[0]).then(function(value){
    //         //         console.log('image-upload uploaded: ' + (_this.isDestroyed?'destroyed':'not-destroyed'));
    //         //         console.log(value);
    //         //         if(!_this.isDestroyed){
    //         //             _this.set('new-image', null);
    //         //             _this.set('isLoading', false);
    //         //             _this.set('image-width', value['width']);
    //         //             _this.set('image-height', value['height']);
    //         //             _this.set('image-url', value['file-name']);
    //         //             _this.set('upload-file', null);
    //         //         }
    //         //         console.log("image-upload done, width: " + value['width']);
    //         //         resolve(value);
    //         //     });
    //         }
    //       });
    // },
    init: function init() {
      this._super();
    }
  });
});