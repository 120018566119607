define('gemini/helpers/if_odd', ['exports', 'ember'], function (exports, _ember) {
    exports.if_odd = if_odd;

    function if_odd(conditional, options) {
        console.log(conditional);
        console.log(options);
        return conditional % 2 === 1;
    }

    exports['default'] = _ember['default'].Helper.helper(if_odd);
});