define("gemini/templates/app/news/item", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 11,
                        "column": 22
                      },
                      "end": {
                        "line": 11,
                        "column": 57
                      }
                    },
                    "moduleName": "gemini/templates/app/news/item.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "model.subject", ["loc", [null, [11, 40], [11, 57]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 10,
                      "column": 18
                    },
                    "end": {
                      "line": 12,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "text.headline", [], [], 0, null, ["loc", [null, [11, 22], [11, 75]]]]],
                locals: ["text"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 9,
                    "column": 14
                  },
                  "end": {
                    "line": 13,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/news/item.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "title.text", [], [], 0, null, ["loc", [null, [10, 18], [12, 33]]]]],
              locals: ["title"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 17,
                        "column": 26
                      },
                      "end": {
                        "line": 19,
                        "column": 26
                      }
                    },
                    "moduleName": "gemini/templates/app/news/item.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              Zichtbaar\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 19,
                        "column": 26
                      },
                      "end": {
                        "line": 21,
                        "column": 26
                      }
                    },
                    "moduleName": "gemini/templates/app/news/item.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                              Niet zichtbaar\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 16,
                      "column": 22
                    },
                    "end": {
                      "line": 22,
                      "column": 22
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "model.active", ["loc", [null, [17, 32], [17, 44]]]]], [], 0, 1, ["loc", [null, [17, 26], [21, 33]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 22
                    },
                    "end": {
                      "line": 33,
                      "column": 110
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["news.send-push-notification"], [], ["loc", [null, [33, 75], [33, 110]]]]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 22
                    },
                    "end": {
                      "line": 34,
                      "column": 100
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["save"], [], ["loc", [null, [34, 88], [34, 100]]]]],
                locals: [],
                templates: []
              };
            })();
            var child3 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 22
                    },
                    "end": {
                      "line": 35,
                      "column": 89
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["remove"], [], ["loc", [null, [35, 75], [35, 89]]]]],
                locals: [],
                templates: []
              };
            })();
            var child4 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 22
                    },
                    "end": {
                      "line": 36,
                      "column": 79
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["inline", "t", ["cancel"], [], ["loc", [null, [36, 65], [36, 79]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 14
                  },
                  "end": {
                    "line": 38,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/news/item.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "layout", "row");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "layout-column");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "md-actions");
                dom.setAttribute(el1, "layout", "row");
                dom.setAttribute(el1, "layout-align", "end center");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "save-update");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [7]);
                var morphs = new Array(8);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                morphs[3] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                morphs[4] = dom.createMorphAt(element0, 3, 3);
                morphs[5] = dom.createMorphAt(element0, 5, 5);
                morphs[6] = dom.createMorphAt(element0, 7, 7);
                morphs[7] = dom.createMorphAt(element0, 9, 9);
                return morphs;
              },
              statements: [["block", "paper-switch", [], ["primary", true, "value", ["subexpr", "@mut", [["get", "model.active", ["loc", [null, [16, 57], [16, 69]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.active", ["loc", [null, [16, 92], [16, 104]]]]], [], ["loc", [null, [16, 87], [16, 105]]]]], [], ["loc", [null, [16, 79], [16, 106]]]]], 0, null, ["loc", [null, [16, 22], [22, 39]]]], ["inline", "paper-input", [], ["flex", 100, "label", "Onderwerp", "value", ["subexpr", "@mut", [["get", "model.subject", ["loc", [null, [25, 69], [25, 82]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "model.subject", ["loc", [null, [25, 105], [25, 118]]]]], [], ["loc", [null, [25, 100], [25, 119]]]]], [], ["loc", [null, [25, 92], [25, 120]]]]], ["loc", [null, [25, 22], [25, 122]]]], ["inline", "content-manager", [], ["firebase-end-point", ["subexpr", "concat", ["newsitems/", ["get", "model.id", ["loc", [null, [28, 76], [28, 84]]]], "/content"], [], ["loc", [null, [28, 55], [28, 96]]]]], ["loc", [null, [28, 18], [28, 98]]]], ["content", "saveUpdate", ["loc", [null, [32, 48], [32, 62]]]], ["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", ["push"], [], ["loc", [null, [33, 58], [33, 73]]]]], 1, null, ["loc", [null, [33, 22], [33, 127]]]], ["block", "paper-button", [], ["raised", true, "primary", true, "onClick", ["subexpr", "action", ["save"], [], ["loc", [null, [34, 71], [34, 86]]]]], 2, null, ["loc", [null, [34, 22], [34, 117]]]], ["block", "paper-button", [], ["onClick", ["subexpr", "action", ["remove"], [], ["loc", [null, [35, 46], [35, 63]]]], "warn", true], 3, null, ["loc", [null, [35, 22], [35, 106]]]], ["block", "paper-button", [], ["onClick", ["subexpr", "action", ["cancel"], [], ["loc", [null, [36, 46], [36, 63]]]]], 4, null, ["loc", [null, [36, 22], [36, 96]]]]],
              locals: [],
              templates: [child0, child1, child2, child3, child4]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 10
                },
                "end": {
                  "line": 39,
                  "column": 10
                }
              },
              "moduleName": "gemini/templates/app/news/item.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "card.title", [], [], 0, null, ["loc", [null, [9, 14], [13, 29]]]], ["block", "card.content", [], [], 1, null, ["loc", [null, [14, 14], [38, 31]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 6
              },
              "end": {
                "line": 40,
                "column": 6
              }
            },
            "moduleName": "gemini/templates/app/news/item.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "card.content", [], [], 0, null, ["loc", [null, [8, 10], [39, 27]]]]],
          locals: ["card"],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 6
              },
              "end": {
                "line": 44,
                "column": 6
              }
            },
            "moduleName": "gemini/templates/app/news/item.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 50,
                        "column": 22
                      },
                      "end": {
                        "line": 50,
                        "column": 48
                      }
                    },
                    "moduleName": "gemini/templates/app/news/item.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Feedback");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 18
                    },
                    "end": {
                      "line": 51,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/news/item.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "text.headline", [], [], 0, null, ["loc", [null, [50, 22], [50, 66]]]]],
                locals: ["text"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 48,
                    "column": 14
                  },
                  "end": {
                    "line": 52,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/news/item.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "title.text", [], [], 0, null, ["loc", [null, [49, 18], [51, 33]]]]],
              locals: ["title"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 14
                  },
                  "end": {
                    "line": 55,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/news/item.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "feedback-manager", [], ["firebase-url", ["subexpr", "concat", ["feedback/news/", ["get", "model.id", ["loc", [null, [54, 75], [54, 83]]]]], [], ["loc", [null, [54, 50], [54, 84]]]]], ["loc", [null, [54, 18], [54, 86]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 10
                },
                "end": {
                  "line": 56,
                  "column": 10
                }
              },
              "moduleName": "gemini/templates/app/news/item.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "card.title", [], [], 0, null, ["loc", [null, [48, 14], [52, 29]]]], ["block", "card.content", [], [], 1, null, ["loc", [null, [53, 14], [55, 31]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 6
              },
              "end": {
                "line": 57,
                "column": 6
              }
            },
            "moduleName": "gemini/templates/app/news/item.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "card.content", [], [], 0, null, ["loc", [null, [47, 10], [56, 27]]]]],
          locals: ["card"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 58,
              "column": 2
            }
          },
          "moduleName": "gemini/templates/app/news/item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  an\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "paper-card", [], [], 0, null, ["loc", [null, [7, 6], [40, 21]]]], ["block", "if", [["get", "addNotification", ["loc", [null, [42, 12], [42, 27]]]]], [], 1, null, ["loc", [null, [42, 6], [44, 13]]]], ["block", "paper-card", [], [], 2, null, ["loc", [null, [46, 6], [57, 21]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 59,
            "column": 6
          }
        },
        "moduleName": "gemini/templates/app/news/item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flex-100");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flex-gt-lg-70 flex-gt-md-80 flex-100");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "page-toolbar", [], ["pageTitle", ["subexpr", "@mut", [["get", "pageTitle", ["loc", [null, [2, 27], [2, 36]]]]], [], []]], ["loc", [null, [2, 2], [2, 38]]]], ["block", "paper-content", [], ["class", "md-padding"], 0, null, ["loc", [null, [5, 2], [58, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});