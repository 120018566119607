define("gemini/routes/app", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({

    model: function model() {
      return this.store.find("user", this.get("session.currentUser.uid"));
    },

    afterModel: function afterModel(model) {
      var _this = this;
      setInterval(function () {
        model.set("admin-last-active-from-web-panel", new Date());
        model.save();
      }, 60000); //every minute
    },

    actions: {
      error: function error(_error) {
        console.log(_error);
        console.log(_error.code);
        if (_error.code === 'PERMISSION_DENIED') {
          console.log("SIGNING OUT!!");
          this.get("session").close();
          return this.transitionTo('signin');
        } else {
          // Let the route above this handle the error.
          return true;
        }
      }
    }

  });
});