define("gemini/controllers/signin", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    email: "",
    password: "",

    isLoading: false,

    error: "",

    // beforeModel: function() {
    //     if (this.get("session") !== undefined && this.get("session").isAuthenticated) {
    //         console.log(this.get("session"));
    //         console.log("signed-in");
    //         return this.transitionTo('index');
    //     }
    // },
    actions: {
      signIn: function signIn() {
        console.log("signIn()");
        this.set('isLoading', true);
        var controller = this;

        if (this.get("email") === "" || this.get("password") === "") {
          controller.set('isLoading', false);
          controller.set('error', "Please enter an email address and password.");
          return;
        }

        var data = { "email": this.get("email"), "password": this.get("password"), "device": "ADMIN" };
        _ember["default"].$.ajax({
          method: "POST",
          url: "https://gemini-back-end.livemegawatt.com/auth/sign-in",
          data: data,
          dataType: "json",
          success: function success(returnData) {
            var token = returnData.token;
            if (token === undefined) {
              controller.set('isLoading', false);
              controller.set('error', "Could not sign in.");
              return;
            }

            controller.get('session').open('firebase', {
              provider: 'custom',
              token: token
            }).then(function () {
              controller.set('email', null);
              controller.set('password', null);
              controller.set('isLoading', false);
              controller.transitionToRoute('app');
            });
          }
        }).fail(function (jqxhr) {
          var response = jqxhr.responseJSON;
          var reason = "Could not sign in.";
          if (response && response.e === 3) {
            var waitUntil = new Date(response.time);
            var now = new Date();
            var difference = waitUntil.getTime() - now.getTime();
            var minutes = Math.max(0, Math.ceil(difference / 1000 / 60));
            reason = "Too many wrong attempts. Please wait " + minutes + " minute" + (minutes !== 1 ? "s" : "") + ".";
          }
          controller.set('isLoading', false);
          controller.set('error', reason);
        });

        // this.get('session').open('firebase', {
        //     provider: 'password',
        //     email: this.get('email') || '',
        //     password: this.get('password') || '',
        // }).then(() => {
        //     controller.set('email', null);
        //     controller.set('password', null);
        //     this.set('isLoading', false);
        //     controller.transitionToRoute('app');
        // }, (error) => {
        //   console.log(error);
        //     this.set('isLoading', false);
        //     this.set('error', "Could not sign in.");
        // });
      }
    }
  });
});