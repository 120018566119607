define('gemini/app', ['exports', 'ember', 'gemini/resolver', 'ember-load-initializers', 'gemini/config/environment'], function (exports, _ember, _geminiResolver, _emberLoadInitializers, _geminiConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _geminiConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _geminiConfigEnvironment['default'].podModulePrefix,
    Resolver: _geminiResolver['default'],
    locale: 'en'
  });

  (0, _emberLoadInitializers['default'])(App, _geminiConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});