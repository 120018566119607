define("gemini/templates/app", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 31
                },
                "end": {
                  "line": 8,
                  "column": 96
                }
              },
              "moduleName": "gemini/templates/app.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["content", "model.previous-signed-in-text", ["loc", [null, [8, 63], [8, 96]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 8,
                  "column": 96
                },
                "end": {
                  "line": 8,
                  "column": 109
                }
              },
              "moduleName": "gemini/templates/app.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("never");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 8
                    },
                    "end": {
                      "line": 14,
                      "column": 168
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["inline", "paper-icon", ["dashboard"], [], ["loc", [null, [14, 114], [14, 140]]]], ["inline", "t", ["index.in-menu"], [], ["loc", [null, [14, 143], [14, 164]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 16,
                        "column": 10
                      },
                      "end": {
                        "line": 16,
                        "column": 170
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["question-answer"], [], ["loc", [null, [16, 112], [16, 144]]]], ["inline", "t", ["faq.in-menu"], [], ["loc", [null, [16, 147], [16, 166]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 8
                    },
                    "end": {
                      "line": 17,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.faq", ["get", "appCtrl.currentRouteName", ["loc", [null, [16, 51], [16, 75]]]]], [], ["loc", [null, [16, 30], [16, 76]]]], "onClick", ["subexpr", "transition-to", ["app.faq"], [], ["loc", [null, [16, 85], [16, 110]]]]], 0, null, ["loc", [null, [16, 10], [16, 184]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child2 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 19,
                        "column": 10
                      },
                      "end": {
                        "line": 19,
                        "column": 176
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["picture-in-picture"], [], ["loc", [null, [19, 114], [19, 149]]]], ["inline", "t", ["news.in-menu"], [], ["loc", [null, [19, 152], [19, 172]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 8
                    },
                    "end": {
                      "line": 20,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.news", ["get", "appCtrl.currentRouteName", ["loc", [null, [19, 52], [19, 76]]]]], [], ["loc", [null, [19, 30], [19, 77]]]], "onClick", ["subexpr", "transition-to", ["app.news"], [], ["loc", [null, [19, 86], [19, 112]]]]], 0, null, ["loc", [null, [19, 10], [19, 190]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child3 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 22,
                        "column": 10
                      },
                      "end": {
                        "line": 22,
                        "column": 181
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["perm-device-information"], [], ["loc", [null, [22, 114], [22, 154]]]], ["inline", "t", ["info.in-menu"], [], ["loc", [null, [22, 157], [22, 177]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 8
                    },
                    "end": {
                      "line": 23,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.info", ["get", "appCtrl.currentRouteName", ["loc", [null, [22, 52], [22, 76]]]]], [], ["loc", [null, [22, 30], [22, 77]]]], "onClick", ["subexpr", "transition-to", ["app.info"], [], ["loc", [null, [22, 86], [22, 112]]]]], 0, null, ["loc", [null, [22, 10], [22, 195]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child4 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 25,
                        "column": 10
                      },
                      "end": {
                        "line": 25,
                        "column": 172
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["email"], [], ["loc", [null, [25, 120], [25, 142]]]], ["inline", "t", ["contact.in-menu"], [], ["loc", [null, [25, 145], [25, 168]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 24,
                      "column": 8
                    },
                    "end": {
                      "line": 26,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.contact", ["get", "appCtrl.currentRouteName", ["loc", [null, [25, 55], [25, 79]]]]], [], ["loc", [null, [25, 30], [25, 80]]]], "onClick", ["subexpr", "transition-to", ["app.contact"], [], ["loc", [null, [25, 89], [25, 118]]]]], 0, null, ["loc", [null, [25, 10], [25, 186]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child5 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 28,
                        "column": 10
                      },
                      "end": {
                        "line": 28,
                        "column": 171
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["notifications"], [], ["loc", [null, [28, 114], [28, 144]]]], ["inline", "t", ["push.in-menu"], [], ["loc", [null, [28, 147], [28, 167]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 27,
                      "column": 8
                    },
                    "end": {
                      "line": 29,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.push", ["get", "appCtrl.currentRouteName", ["loc", [null, [28, 52], [28, 76]]]]], [], ["loc", [null, [28, 30], [28, 77]]]], "onClick", ["subexpr", "transition-to", ["app.push"], [], ["loc", [null, [28, 86], [28, 112]]]]], 0, null, ["loc", [null, [28, 10], [28, 185]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child6 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 31,
                        "column": 10
                      },
                      "end": {
                        "line": 31,
                        "column": 166
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["cloud"], [], ["loc", [null, [31, 116], [31, 138]]]], ["inline", "t", ["files.in-menu"], [], ["loc", [null, [31, 141], [31, 162]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 8
                    },
                    "end": {
                      "line": 32,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.files", ["get", "appCtrl.currentRouteName", ["loc", [null, [31, 53], [31, 77]]]]], [], ["loc", [null, [31, 30], [31, 78]]]], "onClick", ["subexpr", "transition-to", ["app.files"], [], ["loc", [null, [31, 87], [31, 114]]]]], 0, null, ["loc", [null, [31, 10], [31, 180]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child7 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 10
                      },
                      "end": {
                        "line": 37,
                        "column": 168
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["assignment"], [], ["loc", [null, [37, 114], [37, 141]]]], ["inline", "t", ["logs.in-menu"], [], ["loc", [null, [37, 144], [37, 164]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 36,
                      "column": 8
                    },
                    "end": {
                      "line": 38,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.logs", ["get", "appCtrl.currentRouteName", ["loc", [null, [37, 52], [37, 76]]]]], [], ["loc", [null, [37, 30], [37, 77]]]], "onClick", ["subexpr", "transition-to", ["app.logs"], [], ["loc", [null, [37, 86], [37, 112]]]]], 0, null, ["loc", [null, [37, 10], [37, 182]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child8 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 40,
                        "column": 10
                      },
                      "end": {
                        "line": 40,
                        "column": 179
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["supervisor-account"], [], ["loc", [null, [40, 116], [40, 151]]]], ["inline", "t", ["users.in-menu"], [], ["loc", [null, [40, 154], [40, 175]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 8
                    },
                    "end": {
                      "line": 41,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.users", ["get", "appCtrl.currentRouteName", ["loc", [null, [40, 53], [40, 77]]]]], [], ["loc", [null, [40, 30], [40, 78]]]], "onClick", ["subexpr", "transition-to", ["app.users"], [], ["loc", [null, [40, 87], [40, 114]]]]], 0, null, ["loc", [null, [40, 10], [40, 193]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child9 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 43,
                        "column": 10
                      },
                      "end": {
                        "line": 43,
                        "column": 178
                      }
                    },
                    "moduleName": "gemini/templates/app.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("p");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    dom.insertBoundary(fragment, 0);
                    return morphs;
                  },
                  statements: [["inline", "paper-icon", ["settings"], [], ["loc", [null, [43, 122], [43, 147]]]], ["inline", "t", ["settings.in-menu"], [], ["loc", [null, [43, 150], [43, 174]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 42,
                      "column": 8
                    },
                    "end": {
                      "line": 44,
                      "column": 8
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.settings", ["get", "appCtrl.currentRouteName", ["loc", [null, [43, 56], [43, 80]]]]], [], ["loc", [null, [43, 30], [43, 81]]]], "onClick", ["subexpr", "transition-to", ["app.settings"], [], ["loc", [null, [43, 90], [43, 120]]]]], 0, null, ["loc", [null, [43, 10], [43, 192]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child10 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 45,
                      "column": 8
                    },
                    "end": {
                      "line": 45,
                      "column": 169
                    }
                  },
                  "moduleName": "gemini/templates/app.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("p");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["inline", "paper-icon", ["lock"], [], ["loc", [null, [45, 118], [45, 139]]]], ["inline", "t", ["signout.in-menu"], [], ["loc", [null, [45, 142], [45, 165]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 13,
                    "column": 6
                  },
                  "end": {
                    "line": 52,
                    "column": 6
                  }
                },
                "moduleName": "gemini/templates/app.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "logo-lmw");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("img");
                dom.setAttribute(el2, "src", "assets/images/logo_lmw.png");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "style", "color: #ddd; padding-left: 16px;");
                var el2 = dom.createTextNode("v1.0.2");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(12);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                morphs[5] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
                morphs[7] = dom.createMorphAt(fragment, 10, 10, contextualElement);
                morphs[8] = dom.createMorphAt(fragment, 12, 12, contextualElement);
                morphs[9] = dom.createMorphAt(fragment, 13, 13, contextualElement);
                morphs[10] = dom.createMorphAt(fragment, 14, 14, contextualElement);
                morphs[11] = dom.createMorphAt(fragment, 16, 16, contextualElement);
                return morphs;
              },
              statements: [["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.index", ["get", "appCtrl.currentRouteName", ["loc", [null, [14, 51], [14, 75]]]]], [], ["loc", [null, [14, 28], [14, 76]]]], "onClick", ["subexpr", "transition-to", ["app.index"], [], ["loc", [null, [14, 85], [14, 112]]]]], 0, null, ["loc", [null, [14, 8], [14, 182]]]], ["block", "if", [["get", "modules.faq", ["loc", [null, [15, 14], [15, 25]]]]], [], 1, null, ["loc", [null, [15, 8], [17, 15]]]], ["block", "if", [["get", "modules.news", ["loc", [null, [18, 14], [18, 26]]]]], [], 2, null, ["loc", [null, [18, 8], [20, 15]]]], ["block", "if", [["get", "modules.info", ["loc", [null, [21, 14], [21, 26]]]]], [], 3, null, ["loc", [null, [21, 8], [23, 15]]]], ["block", "if", [["get", "modules.contact", ["loc", [null, [24, 14], [24, 29]]]]], [], 4, null, ["loc", [null, [24, 8], [26, 15]]]], ["block", "if", [["get", "modules.push", ["loc", [null, [27, 14], [27, 26]]]]], [], 5, null, ["loc", [null, [27, 8], [29, 15]]]], ["block", "if", [["get", "modules.files", ["loc", [null, [30, 14], [30, 27]]]]], [], 6, null, ["loc", [null, [30, 8], [32, 15]]]], ["content", "paper-divider", ["loc", [null, [34, 8], [34, 25]]]], ["block", "if", [["get", "modules.logs", ["loc", [null, [36, 14], [36, 26]]]]], [], 7, null, ["loc", [null, [36, 8], [38, 15]]]], ["block", "if", [["get", "modules.users", ["loc", [null, [39, 14], [39, 27]]]]], [], 8, null, ["loc", [null, [39, 8], [41, 15]]]], ["block", "if", [["get", "modules.settings", ["loc", [null, [42, 14], [42, 30]]]]], [], 9, null, ["loc", [null, [42, 8], [44, 15]]]], ["block", "menu-item", [], ["active", ["subexpr", "is-active", ["app.signout", ["get", "appCtrl.currentRouteName", ["loc", [null, [45, 53], [45, 77]]]]], [], ["loc", [null, [45, 28], [45, 78]]]], "onClick", ["subexpr", "transition-to", ["app.signout"], [], ["loc", [null, [45, 87], [45, 116]]]]], 10, null, ["loc", [null, [45, 8], [45, 183]]]]],
              locals: [],
              templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 4
                },
                "end": {
                  "line": 54,
                  "column": 4
                }
              },
              "moduleName": "gemini/templates/app.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "paper-list", [], [], 0, null, ["loc", [null, [13, 6], [52, 21]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 56,
                "column": 2
              }
            },
            "moduleName": "gemini/templates/app.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "logo");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "src", "assets/images/logo_windfarm.png");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "username");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("em");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("small");
            var el3 = dom.createTextNode("Previous sign-in: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [8]), 1, 1);
            morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["content", "model.name", ["loc", [null, [6, 28], [6, 42]]]], ["content", "session.currentUser.email", ["loc", [null, [7, 10], [7, 39]]]], ["block", "if", [["get", "model.previous-signed-in", ["loc", [null, [8, 37], [8, 61]]]]], [], 0, 1, ["loc", [null, [8, 31], [8, 116]]]], ["block", "paper-content", [], [], 2, null, ["loc", [null, [11, 4], [54, 22]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type", "multiple-nodes"]
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 63,
              "column": 0
            }
          },
          "moduleName": "gemini/templates/app.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "flex layout-column");
          dom.setAttribute(el1, "tabindex", "-1");
          dom.setAttribute(el1, "role", "main");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "layout-row layout-align-center-start layout-wrap overflow-auto");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "paper-sidenav", [], ["class", "md-whiteframe-z2 site-sidenav", "lockedOpen", "gt-sm", "open", ["subexpr", "@mut", [["get", "isSidenavOpen", ["loc", [null, [3, 24], [3, 37]]]]], [], []], "onToggle", ["subexpr", "action", [["subexpr", "mut", [["get", "isSidenavOpen", ["loc", [null, [3, 60], [3, 73]]]]], [], ["loc", [null, [3, 55], [3, 74]]]]], [], ["loc", [null, [3, 47], [3, 75]]]]], 0, null, ["loc", [null, [2, 2], [56, 20]]]], ["content", "outlet", ["loc", [null, [60, 6], [60, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 63,
            "column": 28
          }
        },
        "moduleName": "gemini/templates/app.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "paper-sidenav-container", [], ["class", "site-nav-container"], 0, null, ["loc", [null, [1, 0], [63, 28]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});