define('gemini/models/newsitem', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
    exports['default'] = _emberData['default'].Model.extend({
        subject: _emberData['default'].attr('string'),
        message: _emberData['default'].attr('string'),
        image: _emberData['default'].attr('string'),
        active: _emberData['default'].attr('boolean'),
        createdAt: _emberData['default'].attr("date"),
        createdAtI: _emberData['default'].attr("number"), // inverse
        updatedAt: _emberData['default'].attr("date"),
        updatedAtI: _emberData['default'].attr("number"), // inverse

        content: _emberData['default'].attr(),

        messageShort: _ember['default'].computed('message', function () {
            var message = '' + this.get('message');
            if (message.length > 100) {
                message = message.substring(0, 100) + "...";
            }
            return message;
        }),

        paginationLink: "app.news.item"
    });
});