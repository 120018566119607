define('gemini/controllers/app/news', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Controller.extend({
    i18n: _ember['default'].inject.service(),
    sortedModel: _ember['default'].computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],

    columns: [{
      name: "subject",
      label: "Onderwerp",
      primary: true,
      style: _ember['default'].String.htmlSafe('width: 60%')
    }, {
      name: "message",
      label: "Bericht",
      style: _ember['default'].String.htmlSafe('width: 40%')
    }],

    columnsSet: _ember['default'].on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('news.subject'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('news.message'));
    })
  });
});