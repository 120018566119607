define('gemini/routes/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        session: _ember['default'].inject.service('session'),
        i18n: _ember['default'].inject.service(),

        beforeModel: function beforeModel() {
            var _this = this;

            return this.get('session').fetch()['catch'](function (error) {
                _this.transitionTo('signin');
            });
        },

        afterModel: function afterModel(user) {
            this.set('i18n.locale', 'en');
        },
        actions: {
            transition: function transition() {
                this.transitionToRoute.apply(this, arguments);
            }
        }
    });
});
// app/routes/application.js