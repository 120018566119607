define('gemini/components/fb-log-pagination', ['exports', 'ember', 'gemini/components/fb-pagination', 'ember-i18n'], function (exports, _ember, _geminiComponentsFbPagination, _emberI18n) {
  exports['default'] = _geminiComponentsFbPagination['default'].extend({

    i18n: _ember['default'].inject.service(),
    pageLimit: 20,
    start: null,

    actions: {

      // next: function () {
      //   console.log(this.get("lastKey"));
      //   this.set("start", this.get("lastKey"));
      // }

    }
  });
});