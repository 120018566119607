define('gemini/components/paper-confirm-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    confirm: false,

    actions: {
      askConfirm: function askConfirm() {
        this.set('confirm', true);
      },
      cancelConfirm: function cancelConfirm() {
        this.set('confirm', false);
      },
      performAction: function performAction(params) {
        this.set('confirm', false);
        params();
      }
    }
  });
});