define('gemini/helpers/transition-to', ['exports', 'ember-route-action/helpers/transition-to'], function (exports, _emberRouteActionHelpersTransitionTo) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberRouteActionHelpersTransitionTo['default'];
    }
  });
  Object.defineProperty(exports, 'transitionTo', {
    enumerable: true,
    get: function get() {
      return _emberRouteActionHelpersTransitionTo.transitionTo;
    }
  });
});