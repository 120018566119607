define('gemini/models/user', ['exports', 'ember-data', 'npm:moment'], function (exports, _emberData, _npmMoment) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr("string"),
    email: _emberData['default'].attr("string"),

    resetPassword: _emberData['default'].attr("boolean"),

    disabled: _emberData['default'].attr("boolean"),
    "device-count": _emberData['default'].attr('number'),
    admin: _emberData['default'].attr('boolean'),
    password: _emberData['default'].attr("string"),

    "last-signed-in": _emberData['default'].attr(),

    "admin-last-active-from-web-panel": _emberData['default'].attr("date"),

    devices: _emberData['default'].attr(),

    createdAt: _emberData['default'].attr("date"),
    createdAtI: _emberData['default'].attr("number"), // inverse

    paginationLink: "app.users.user",

    "previous-signed-in": _emberData['default'].attr(),
    "previous-signed-in-text": Ember.computed('previous-signed-in.date', function () {
      var time = (0, _npmMoment['default'])(this.get('previous-signed-in.date'));
      return time.format("ddd D MMM YYYY HH:mm");
    }),
    "last-signed-in-text": Ember.computed('last-signed-in.date', function () {
      var returnValue = "";
      var date = this.get('last-signed-in.date');
      if (date !== undefined && date !== null) {
        var time = (0, _npmMoment['default'])(date);
        returnValue = time.format("ddd D MMM YYYY HH:mm");
      } else {
        returnValue = "never";
      }
      var device = this.get("last-signed-in.device");
      if (device !== undefined && device !== null) {
        returnValue += " (" + device + ")";
      }
      return returnValue;
    })
  });
});