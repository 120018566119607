define('gemini/models/faq', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    question: _emberData['default'].attr('string'),
    answer: _emberData['default'].attr('string'),
    timestamp: _emberData['default'].attr('number'),
    active: _emberData['default'].attr('boolean'),
    createdAt: _emberData['default'].attr("date"),
    createdAtI: _emberData['default'].attr("number"), // inverse
    updatedAt: _emberData['default'].attr("date"),
    updatedAtI: _emberData['default'].attr("number"), // inverse

    content: _emberData['default'].attr(),

    paginationLink: "app.faq.item"
  });
});