define("gemini/components/private-file-upload", ["exports", "ember"], function (exports, _ember) {
  var inject = _ember["default"].inject;
  exports["default"] = _ember["default"].Component.extend({
    firebase: inject.service(),
    session: inject.service(),

    "token": null,

    setToken: _ember["default"].on("init", function () {
      var _this = this;
      console.log('set token');
      this.get('session.currentUser').getToken(false).then(function (token) {
        console.log("token", token);
        _this.set("token", token);
      });
    }),

    upload: _ember["default"].observer('upload-state', function () {
      console.log('uploadState changed private-file-upload.js', this.get('upload-state'));
      if (this.get('upload-state') === "ready") {}
    })
  });
});