define('gemini/components/feedback-manager', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    ref: _ember['default'].inject.service('firebase'),

    model: null,
    "firebase-url": null,

    hasFeedback: false,
    yesCount: 0,
    yesPerc: 0,
    noCount: 0,
    noPerc: 0,
    hasMessages: false,
    messages: [{ icon: "thumb down", text: "hoi" }, { icon: "thumb up", text: "hoi2" }],

    initComponent: _ember['default'].observer('firebase-url', function () {
      console.log("observed firebase-ulr called!");
      var _this = this;
      var ref = this.get('ref');
      ref.child(this.get('firebase-url')).orderByChild('createdAtI').on("value", function (snapshot) {
        var messages = [];
        var yesCount = 0;
        var noCount = 0;
        snapshot.forEach(function (feedback) {
          var opinion = feedback.child('opinion').val();
          var message = feedback.child('message').val();
          var createdAtI = feedback.child('createdAtI').val();

          if (opinion) {
            yesCount++;
          } else {
            noCount++;
          }
          if (message !== "" && message != null) {
            messages.push({
              icon: opinion ? "thumb-up" : "thumb-down",
              text: message,
              date: new Date(-1000 * createdAtI)
            });
          }
        });

        _this.set('yesCount', yesCount);
        _this.set('yesPerc', _ember['default'].String.htmlSafe(Math.round(yesCount / (yesCount + noCount) * 100)));
        _this.set('noCount', noCount);
        _this.set('noPerc', Math.round(noCount / (yesCount + noCount) * 100));
        _this.set('messages', messages);
        _this.set('hasFeedback', yesCount + noCount > 0);
        _this.set('hasMessages', messages.length > 0);
      });
    }),

    initListener: _ember['default'].on("init", function () {
      this.initComponent();
    })
  });
});