define("gemini/controllers/app/news/item", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend(_ember["default"].Evented, {
        pageTitle: "News / Item",
        saveUpdate: "",

        saveTrigger: null,

        actions: {
            save: function save() {
                var _this = this;

                var model = this.get('model');
                model.setProperties({
                    updatedAt: new Date(),
                    updatedAtI: -new Date().getTime()
                });

                model.save().then(function () {
                    _this.set('saveUpdate', 'Opgeslagen!');
                });

                this.set('saveTrigger', Math.random());
                // this.trigger('saveContent');
            },
            cancel: function cancel() {
                this.transitionToRoute('app.news');
            },
            remove: function remove() {
                this.get('model').destroyRecord();
                this.send('cancel');
            },
            push: function push() {
                var model = this.get('model');

                var data = {
                    "title": model.get('subject'),
                    "action-type": "news",
                    "action-key": model.get('id')
                };

                _ember["default"].$.ajax("https://fcm.googleapis.com/fcm/send", {
                    type: "POST",
                    dataType: "json",
                    beforeSend: function beforeSend(xhr) {
                        xhr.setRequestHeader('Authorization', "key=AIzaSyCiGmMbdIp8Djw48XpuTfNpx6_2s5ft3FQ");
                        xhr.setRequestHeader('Content-Type', "application/json");
                    },
                    data: JSON.stringify({
                        data: data,
                        to: "/topics/news",
                        notification: {
                            body: model.get('subject'),
                            icon: 'ic_notification',
                            click_action: 'OPEN_ACTIVITY_NEWS',
                            color: '#388E3C'
                            // title: model.get('subject')
                        }
                    })
                });
            }
        }
    });
});