define('gemini/models/form', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    mail: _emberData['default'].attr('string'),
    message: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr("date"),
    createdAtI: _emberData['default'].attr("number"), // inverse
    content: _emberData['default'].attr(),

    paginationLink: "app.contact.message"
  });
});