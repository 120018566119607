define('gemini/controllers/app/push', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        ref: _ember['default'].inject.service('firebase'),

        title: null,
        message: null,
        image: null,
        upload: [],
        actionValue: null,

        senderId: 429781468292,

        newsitems: [],

        actions: {
            send: function send() {
                if (this.get('upload').length > 0) {
                    var uploadPromises = [];
                    uploadPromises.push(this.get('upload')[0].upload());
                    var _this = this;
                    _ember['default'].RSVP.Promise.all(uploadPromises).then(function (value) {
                        _this.sendNotification(value[0]["file-name"]);
                    });
                } else {
                    this.sendNotification();
                }
            }
        },

        sendNotification: function sendNotification(image) {
            var _this = this;

            var data = {
                "title": _this.get('title'),
                "message": _this.get('message')
            };

            if (image != null) {
                data.image = image;
            }

            if (this.get('actionValue') != null) {
                data["action-type"] = this.get('actionValue').type;
                data["action-key"] = this.get('actionValue').key;
            }

            _ember['default'].$.ajax("https://fcm.googleapis.com/fcm/send", {
                type: "POST",
                dataType: "json",
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader('Authorization', "");
                    xhr.setRequestHeader('Content-Type', "application/json");
                },
                data: JSON.stringify({
                    data: data,
                    to: "/topics/news-dev",
                    notification: {
                        body: _this.get('title'),
                        icon: 'ic_notification',
                        color: '#388E3C'
                        // title : _this.get('title')
                    }
                })
            });
        },

        init: function init() {
            var _this = this;
            var ref = this.get('ref');

            ref.child('/newsitems').on("child_added", function (snapshot) {
                _this.get('newsitems').pushObject({ key: { key: snapshot.key(), value: snapshot.child('subject').val(), type: "news" }, value: snapshot.child('subject').val() });
            });
        }
    });
});