define('gemini/controllers/app/settings/contact', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
  exports['default'] = _ember['default'].Controller.extend({
    ref: _ember['default'].inject.service('firebase'),
    i18n: _ember['default'].inject.service(),

    showField: "contact",

    contactEmail: null,

    saveUpdate: "",

    actions: {
      save: function save() {
        this.set("saveUpdate", (0, _emberI18n.translationMacro)("saved"));
        this.get('ref').child("back-end-settings/contact-mail").set(this.get("contactEmail"));
      }
    },

    initListener: _ember['default'].on("init", function () {
      var _this = this;
      this.get('ref').child("back-end-settings/contact-mail").on("value", function (snapshot) {
        _this.set("contactEmail", snapshot.val());
      });
    })
  });
});