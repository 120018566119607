define('gemini/controllers/app/faq', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {

  // app/controllers/posts.js
  exports['default'] = _ember['default'].Controller.extend({

    i18n: _ember['default'].inject.service(),
    sortedModel: _ember['default'].computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],

    "show-pagination": true,

    columns: [{
      name: "question",
      label: "Vraag",
      primary: true
    }, {
      name: "active",
      label: "Active",
      primary: false,
      style: _ember['default'].String.htmlSafe('width: 15%')
    }],

    columnsSet: _ember['default'].on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('faq.question'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('active'));
    })
  });
});