define("gemini/controllers/app/files", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  exports["default"] = _ember["default"].Controller.extend({
    i18n: _ember["default"].inject.service(),
    "show-pagination": true,
    sortedModel: _ember["default"].computed.sort('model', 'sorting'),
    sorting: ['updatedAtI'],

    columns: [{
      name: "name",
      label: "Naam",
      primary: true
    }, {
      name: "active",
      label: "Active",
      primary: false,
      style: _ember["default"].String.htmlSafe('width: 15%')
    }],

    columnsSet: _ember["default"].on('init', function () {
      this.set('columns.0.i18n', this.get('i18n'));
      this.set('columns.0.label', (0, _emberI18n.translationMacro)('files.file-name'));
      this.set('columns.1.i18n', this.get('i18n'));
      this.set('columns.1.label', (0, _emberI18n.translationMacro)('visible'));
    })
  });
});