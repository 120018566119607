define('gemini/components/fb-pagination', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    ref: _ember['default'].inject.service('firebase'),

    page: 1,

    pageLimit: 5,

    firstPage: true,
    notFirstPage: false,
    lastPage: false,
    notLastPage: true,

    singlePage: false,
    multiPage: true,

    search: "",

    didChange: false,
    lastKey: null,

    pagedContent: _ember['default'].computed('page', 'pageLimit', 'searchedContent', function () {
      var page = this.get('page');
      var pageLimit = this.get('pageLimit');
      var searchedContent = this.get('searchedContent');

      var pagedContent = searchedContent.slice((page - 1) * pageLimit, page * pageLimit);
      return pagedContent;
    }),

    indexedContent: _ember['default'].computed('columns', 'didChange', function () {

      this.set('didChange', false);
      var _this = this;

      console.log('update index');
      var content = this.get('content');
      var indexedContent = [];
      var columns = this.get('columns');
      if (content !== undefined) {
        content.forEach(function (content) {
          _this.set("lastKey", content.get("createdAtI"));
          var searchString = "";
          for (var i = 0; i < columns.length; i++) {
            searchString += content.get(columns[i].name);
          }
          indexedContent.pushObject(searchString);
        });
      }

      return indexedContent;
    }),

    searchedContent: _ember['default'].computed('content', 'indexedContent', 'search', function () {
      console.log("update searched");
      var content = this.get('content');
      var indexedContent = this.get('indexedContent');
      var searchedContent = [];
      var search = this.get('search');

      if (search === "") {
        return content;
      }

      $(indexedContent).each(function (i, indexedContent) {
        if (indexedContent.toLowerCase().includes(search.toLowerCase())) {
          searchedContent.pushObject(content.objectAt(i));
        }
      });

      return searchedContent;
    }),

    entries: _ember['default'].computed("searchedContent", function () {
      return this.get('searchedContent').get('length');
    }),
    pages: _ember['default'].computed('entries', 'pageLimit', function () {
      var pages = Math.ceil(this.get('entries') / this.get('pageLimit'));

      this.set('singlePage', pages === 1);
      this.set('multiPage', pages !== 1);

      return pages;
    }),

    actions: {
      prev: function prev() {
        this.moveTo(-1);
      },
      next: function next() {
        this.moveTo(1);
      }
    },
    moveTo: function moveTo(shift) {
      var page = this.get('page');
      var pages = this.get('pages');
      var pageLimit = this.get("pageLimit");
      var content = this.get("content");

      var needsRefresh = false;

      if (shift === -1 && page > 1) {
        page--;
        needsRefresh = true;
      } else if (shift === 1 && page < pages) {
        page++;
        needsRefresh = true;
      }

      if (needsRefresh) {
        this.set('page', page);
      }
      this.updateButtons();
    },
    updateButtons: _ember['default'].observer("search", "pages", function () {
      var page = this.get('page');
      var pages = this.get('pages');

      if (page === 1) {
        this.set('firstPage', true);
        this.set('notFirstPage', false);
      } else {
        this.set('firstPage', false);
        this.set('notFirstPage', true);
      }
      if (page === pages) {
        this.set('lastPage', true);
        this.set('notLastPage', false);
      } else {
        this.set('lastPage', false);
        this.set('notLastPage', true);
      }
      if (pages === 0) {
        this.set('firstPage', true);
        this.set('notFirstPage', false);
        this.set('lastPage', true);
        this.set('notLastPage', false);
      }
      if (page !== 1 && page !== pages) {
        this.set('firstPage', false);
        this.set('notFirstPage', true);
        this.set('lastPage', false);
        this.set('notLastPage', true);
      }
      console.log("pages", pages);
    }),

    init: function init() {
      this._super();

      var _this = this;
      var columns = this.get('columns');
      for (var i = 0; i < columns.length; i++) {
        console.log('content.@each.' + columns[i].name);
        this.addObserver('content.@each.' + columns[i].name, this, "reIndex");
      }

      console.log("init fb-pagination");

      this.moveTo(0);
    },
    reIndex: function reIndex() {
      console.log("reindex");
      this.set('didChange', true);
    }
  });
});