define("gemini/controllers/app/settings/descriptions", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  exports["default"] = _ember["default"].Controller.extend({
    i18n: _ember["default"].inject.service(),
    showField: "descriptions",

    descriptions: [{
      groupName: "live",
      options: [{
        url: "info/circle-live-winddirection",
        name: "live.winddirection",
        feedback: "feedback/circle-live-winddirection"
      }, {
        url: "info/circle-live-windspeed",
        name: "live.windspeed",
        feedback: "feedback/circle-live-windspeed"
      }, {
        url: "info/circle-live-households",
        name: "live.households",
        feedback: "feedback/circle-live-households"
      }, {
        url: "info/circle-live-co2",
        name: "live.co2",
        feedback: "feedback/circle-live-co2"
      }, {
        url: "info/turbine-status",
        name: "live.turbine-status",
        feedback: "feedback/turbine-status"
      }, {
        url: "info/circle-live-waveheight",
        name: "live.waveheight",
        feedback: "feedback/circle-live-waveheight"
      }]
    }, {
      groupName: "history",
      options: [{
        url: "info/circle-history-production",
        name: "history.production",
        feedback: "feedback/circle-history-production"
      }, {
        url: "info/circle-history-hours",
        name: "history.hours",
        feedback: "feedback/circle-history-hours"
      }, {
        url: "info/circle-history-households",
        name: "history.households",
        feedback: "feedback/circle-history-households"
      }, {
        url: "info/circle-history-co2",
        name: "history.co2",
        feedback: "feedback/circle-history-co2"
      }]
    }, {
      groupName: "other",
      options: [{
        url: "info/privacy",
        name: "other.privacy",
        feedback: "feedback/privacy"
      }, {
        url: "info/disclaimer",
        name: "other.disclaimer",
        feedback: "feedback/disclaimer"
      }, {
        url: "info/licenses",
        name: "other.licenses",
        feedback: "feedback/licenses"
      }]
    }],

    actions: {
      loadDescription: function loadDescription(description) {
        this.set("activeDescription", description);
        console.log(description);
      }
    },

    descriptionsSet: _ember["default"].on('init', function () {
      var _this = this;
      this.get('descriptions').forEach(function (group, groupKey) {
        var path = 'descriptions.' + groupKey;
        _this.set(path + ".i18n", _this.get('i18n'));
        _this.set(path + ".groupName", (0, _emberI18n.translationMacro)("settings.descriptions.groups." + group.groupName));

        group.options.forEach(function (option, optionKey) {
          console.log(groupKey, optionKey, option);
          var path = 'descriptions.' + groupKey + ".options." + optionKey;
          _this.set(path + ".i18n", _this.get('i18n'));
          _this.set(path + ".name", (0, _emberI18n.translationMacro)("settings.descriptions." + option.name));
        });
      });
    })
  });
});