define("gemini/components/content-manager", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    content: [],
    "firebase-end-point": "/",
    "show-save-button": true,

    "image-uploads": [],

    "save-trigger": null,
    "is-saving": false,

    ref: _ember["default"].inject.service('firebase'),

    actions: {

      removeContent: function removeContent(item) {
        this.get('content').removeObject(item);
      },
      addContent: function addContent(index) {
        console.log('add-content');
        console.log(this.get('save-trigger'));
        if (index === "end") {
          console.log("add object");
          this.get('content').pushObject({ type: 'new' });
        } else {
          console.log("add splice object");
          this.get('content').insertAt(index, { type: 'new' });
        }
      },
      setNewContentType: function setNewContentType(item, type) {
        _ember["default"].set(item, 'type', type);
      },
      moveContent: function moveContent(direction, index) {
        var switchIndex = index + (direction === 'up' ? -1 : 1);

        console.log("move (" + index + ", " + switchIndex + ")");

        var content = this.get('content');
        var tmp = content[switchIndex];
        this.get('content').replace(switchIndex, 1, content[index]);
        this.get('content').replace(index, 1, tmp);
      },
      saveContent: function saveContent() {
        this.saveContent();
      },
      testIsSaving: function testIsSaving() {
        console.log('test is saving');
        this.set('is-saving', !this.get('is-saving'));
      }
    },

    saveContent: _ember["default"].observer('save-trigger', function () {
      console.log('save-trigger');
      this.set("is-saving", true);
      var uploadPromises = [];
      if (this.get('image-uploads') != null) {
        this.get('image-uploads').forEach(function (imageUpload) {
          uploadPromises.push(imageUpload.upload());
        });
      }
      var _this = this;
      var ref = _this.get('ref');
      _ember["default"].RSVP.Promise.all(uploadPromises).then(function (value) {
        return ref.child(_this.get('firebase-end-point')).set(_this.get('content')); // eerste uploaden van ima
      }).then(function (value) {
        _this.set("is-saving", false);
      });
    }),

    isSavingListener: _ember["default"].observer("is-saving", function () {
      this.sendAction('onSavingChanged', this.get('is-saving'));
    }),

    linkValidation: [{
      'message': 'Link is niet goed ingevuld. De link moet beginnen met http://',
      validate: function validate(value) {
        return (/^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value)
        );;
      }
    }],

    testObserver: _ember["default"].observer('content.[]', function () {
      console.log("here");
    }),

    initComponent: _ember["default"].observer('firebase-end-point', function () {
      var _this = this;
      var ref = this.get('ref');
      ref.child(this.get("firebase-end-point")).on("value", function (snapshot) {
        var content = [];
        if (snapshot.val() != null) {
          snapshot.val().forEach(function (item) {
            content.pushObject(item);
          });
        }
        _this.set('content', content);
      });
    }),

    init: function init() {
      console.log('init content-manager');
      this._super();
      this.initComponent();
    }
  });
});