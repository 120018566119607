define("gemini/controllers/app/contact/message", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    pageTitle: "Contact / Item",
    actions: {
      mail: function mail(email) {
        document.location.href = "mailto:" + email;
      },
      cancel: function cancel() {
        this.transitionToRoute('app.contact');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      }
    }
  });
});