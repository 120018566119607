define("gemini/templates/components/content-manager", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 20
                },
                "end": {
                  "line": 7,
                  "column": 124
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "paper-icon", ["arrow_upward"], [], ["loc", [null, [7, 95], [7, 124]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 16
              },
              "end": {
                "line": 8,
                "column": 16
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "paper-button", [], ["onClick", ["subexpr", "action", ["moveContent", "up", ["get", "index", ["loc", [null, [7, 71], [7, 76]]]]], [], ["loc", [null, [7, 44], [7, 77]]]], "iconButton", true], 0, null, ["loc", [null, [7, 20], [7, 141]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 20
                },
                "end": {
                  "line": 10,
                  "column": 128
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "paper-icon", ["arrow_downward"], [], ["loc", [null, [10, 97], [10, 128]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 16
              },
              "end": {
                "line": 11,
                "column": 16
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "paper-button", [], ["onClick", ["subexpr", "action", ["moveContent", "down", ["get", "index", ["loc", [null, [10, 73], [10, 78]]]]], [], ["loc", [null, [10, 44], [10, 79]]]], "iconButton", true], 0, null, ["loc", [null, [10, 20], [10, 145]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 16
              },
              "end": {
                "line": 14,
                "column": 112
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "paper-icon", ["add_circle"], [], ["loc", [null, [14, 85], [14, 112]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 16
                },
                "end": {
                  "line": 18,
                  "column": 107
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 12
              },
              "end": {
                "line": 19,
                "column": 12
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "paper-input", [], ["flex", 100, "textarea", true, "value", ["subexpr", "@mut", [["get", "item.value", ["loc", [null, [18, 60], [18, 70]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "item.value", ["loc", [null, [18, 93], [18, 103]]]]], [], ["loc", [null, [18, 88], [18, 104]]]]], [], ["loc", [null, [18, 80], [18, 105]]]]], 0, null, ["loc", [null, [18, 16], [18, 123]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 16
                },
                "end": {
                  "line": 22,
                  "column": 106
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 16
                },
                "end": {
                  "line": 23,
                  "column": 108
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 12
              },
              "end": {
                "line": 24,
                "column": 12
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "paper-input", [], ["flex", 50, "textarea", true, "value", ["subexpr", "@mut", [["get", "item.value", ["loc", [null, [22, 59], [22, 69]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "item.value", ["loc", [null, [22, 92], [22, 102]]]]], [], ["loc", [null, [22, 87], [22, 103]]]]], [], ["loc", [null, [22, 79], [22, 104]]]]], 0, null, ["loc", [null, [22, 16], [22, 122]]]], ["block", "paper-input", [], ["flex", 50, "textarea", true, "value", ["subexpr", "@mut", [["get", "item.value2", ["loc", [null, [23, 59], [23, 70]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "item.value2", ["loc", [null, [23, 93], [23, 104]]]]], [], ["loc", [null, [23, 88], [23, 105]]]]], [], ["loc", [null, [23, 80], [23, 106]]]]], 1, null, ["loc", [null, [23, 16], [23, 124]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 12
              },
              "end": {
                "line": 28,
                "column": 12
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "image-upload", [], ["flex", 100, "url", "https://gemini-back-end.livemegawatt.com/file-upload", "image-url", ["subexpr", "@mut", [["get", "item.image-url", ["loc", [null, [27, 109], [27, 123]]]]], [], []], "image-width", ["subexpr", "@mut", [["get", "item.image-width", ["loc", [null, [27, 136], [27, 152]]]]], [], []], "image-height", ["subexpr", "@mut", [["get", "item.image-height", ["loc", [null, [27, 166], [27, 183]]]]], [], []], "firebase-end-point", ["subexpr", "concat", [["get", "firebase-end-point", ["loc", [null, [27, 211], [27, 229]]]], "/", ["get", "index", ["loc", [null, [27, 234], [27, 239]]]]], [], ["loc", [null, [27, 203], [27, 240]]]], "image-upload-array", ["subexpr", "@mut", [["get", "image-uploads", ["loc", [null, [27, 260], [27, 273]]]]], [], []]], ["loc", [null, [27, 16], [27, 275]]]]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 56
                },
                "end": {
                  "line": 32,
                  "column": 173
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 56
                },
                "end": {
                  "line": 35,
                  "column": 206
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 12
              },
              "end": {
                "line": 37,
                "column": 12
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "layout-row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "layout-column flex-100");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "layout-row");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "layout-column flex-100");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 0, 0);
            return morphs;
          },
          statements: [["block", "paper-input", [], ["flex", 100, "label", ["subexpr", "t", ["c.content-manager.link.text"], [], ["loc", [null, [32, 86], [32, 119]]]], "value", ["subexpr", "@mut", [["get", "item.value", ["loc", [null, [32, 126], [32, 136]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "item.value", ["loc", [null, [32, 159], [32, 169]]]]], [], ["loc", [null, [32, 154], [32, 170]]]]], [], ["loc", [null, [32, 146], [32, 171]]]]], 0, null, ["loc", [null, [32, 56], [32, 189]]]], ["block", "paper-input", [], ["flex", 100, "label", ["subexpr", "t", ["c.content-manager.link.location"], [], ["loc", [null, [35, 86], [35, 123]]]], "value", ["subexpr", "@mut", [["get", "item.url", ["loc", [null, [35, 130], [35, 138]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "item.url", ["loc", [null, [35, 161], [35, 169]]]]], [], ["loc", [null, [35, 156], [35, 170]]]]], [], ["loc", [null, [35, 148], [35, 171]]]], "customValidations", ["subexpr", "@mut", [["get", "linkValidation", ["loc", [null, [35, 190], [35, 204]]]]], [], []]], 1, null, ["loc", [null, [35, 56], [35, 222]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child7 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 20
                },
                "end": {
                  "line": 40,
                  "column": 136
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["c.content-manager.types.image"], [], ["loc", [null, [40, 99], [40, 136]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 20
                },
                "end": {
                  "line": 41,
                  "column": 134
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["c.content-manager.types.text"], [], ["loc", [null, [41, 98], [41, 134]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 20
                },
                "end": {
                  "line": 42,
                  "column": 144
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["c.content-manager.types.two-columns"], [], ["loc", [null, [42, 101], [42, 144]]]]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 20
                },
                "end": {
                  "line": 43,
                  "column": 134
                }
              },
              "moduleName": "gemini/templates/components/content-manager.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", ["c.content-manager.types.link"], [], ["loc", [null, [43, 98], [43, 134]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 12
              },
              "end": {
                "line": 45,
                "column": 12
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "height: 150px;");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element0, 1, 1);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            morphs[2] = dom.createMorphAt(element0, 5, 5);
            morphs[3] = dom.createMorphAt(element0, 7, 7);
            return morphs;
          },
          statements: [["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", ["setNewContentType", ["get", "item", ["loc", [null, [40, 84], [40, 88]]]], "image"], [], ["loc", [null, [40, 56], [40, 97]]]]], 0, null, ["loc", [null, [40, 20], [40, 153]]]], ["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", ["setNewContentType", ["get", "item", ["loc", [null, [41, 84], [41, 88]]]], "text"], [], ["loc", [null, [41, 56], [41, 96]]]]], 1, null, ["loc", [null, [41, 20], [41, 151]]]], ["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", ["setNewContentType", ["get", "item", ["loc", [null, [42, 84], [42, 88]]]], "columns"], [], ["loc", [null, [42, 56], [42, 99]]]]], 2, null, ["loc", [null, [42, 20], [42, 161]]]], ["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", ["setNewContentType", ["get", "item", ["loc", [null, [43, 84], [43, 88]]]], "link"], [], ["loc", [null, [43, 56], [43, 96]]]]], 3, null, ["loc", [null, [43, 20], [43, 151]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 47,
              "column": 4
            }
          },
          "moduleName": "gemini/templates/components/content-manager.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "layout-column full-width-padding");
          dom.setAttribute(el1, "style", "position: relative; padding-left: 88px; min-height: 200px;");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "style", "position: absolute; left: 0; top: 0; bottom: 0; background: rgba(0,0,0,.02); padding: 8px;");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "style", "position: absolute; top: -20px; left: 45px;");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(element2, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 3, 3);
          morphs[2] = dom.createMorphAt(element2, 4, 4);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
          morphs[4] = dom.createMorphAt(element1, 5, 5);
          morphs[5] = dom.createMorphAt(element1, 7, 7);
          morphs[6] = dom.createMorphAt(element1, 9, 9);
          morphs[7] = dom.createMorphAt(element1, 11, 11);
          morphs[8] = dom.createMorphAt(element1, 12, 12);
          return morphs;
        },
        statements: [["inline", "paper-remove-button", [], ["onClick", ["subexpr", "action", ["removeContent", ["get", "item", ["loc", [null, [5, 70], [5, 74]]]]], [], ["loc", [null, [5, 46], [5, 75]]]]], ["loc", [null, [5, 16], [5, 77]]]], ["block", "unless", [["subexpr", "eq", [["get", "index", ["loc", [null, [6, 30], [6, 35]]]], 0], [], ["loc", [null, [6, 26], [6, 38]]]]], [], 0, null, ["loc", [null, [6, 16], [8, 27]]]], ["block", "unless", [["subexpr", "eq_minus_one", [["get", "index", ["loc", [null, [9, 40], [9, 45]]]], ["get", "content.length", ["loc", [null, [9, 46], [9, 60]]]]], [], ["loc", [null, [9, 26], [9, 61]]]]], [], 1, null, ["loc", [null, [9, 16], [11, 27]]]], ["block", "paper-button", [], ["iconButton", true, "onClick", ["subexpr", "action", ["addContent", ["get", "index", ["loc", [null, [14, 77], [14, 82]]]]], [], ["loc", [null, [14, 56], [14, 83]]]]], 2, null, ["loc", [null, [14, 16], [14, 129]]]], ["block", "if", [["subexpr", "eq", [["get", "item.type", ["loc", [null, [17, 22], [17, 31]]]], "text"], [], ["loc", [null, [17, 18], [17, 39]]]]], [], 3, null, ["loc", [null, [17, 12], [19, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "item.type", ["loc", [null, [21, 22], [21, 31]]]], "columns"], [], ["loc", [null, [21, 18], [21, 42]]]]], [], 4, null, ["loc", [null, [21, 12], [24, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "item.type", ["loc", [null, [26, 22], [26, 31]]]], "image"], [], ["loc", [null, [26, 18], [26, 40]]]]], [], 5, null, ["loc", [null, [26, 12], [28, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "item.type", ["loc", [null, [30, 22], [30, 31]]]], "link"], [], ["loc", [null, [30, 18], [30, 39]]]]], [], 6, null, ["loc", [null, [30, 12], [37, 19]]]], ["block", "if", [["subexpr", "eq", [["get", "item.type", ["loc", [null, [38, 22], [38, 31]]]], "new"], [], ["loc", [null, [38, 18], [38, 38]]]]], [], 7, null, ["loc", [null, [38, 12], [45, 19]]]]],
        locals: ["item", "index"],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 4
            },
            "end": {
              "line": 50,
              "column": 100
            }
          },
          "moduleName": "gemini/templates/components/content-manager.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "paper-icon", ["add_circle"], [], ["loc", [null, [50, 73], [50, 100]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 4
              },
              "end": {
                "line": 55,
                "column": 89
              }
            },
            "moduleName": "gemini/templates/components/content-manager.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["save"], [], ["loc", [null, [55, 77], [55, 89]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 0
            },
            "end": {
              "line": 57,
              "column": 0
            }
          },
          "moduleName": "gemini/templates/components/content-manager.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "layout-row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "flex");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
          return morphs;
        },
        statements: [["block", "paper-button", [], ["raised", true, "primary", true, "onClick", ["subexpr", "action", ["saveContent"], [], ["loc", [null, [55, 53], [55, 75]]]]], 0, null, ["loc", [null, [55, 4], [55, 106]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 7
          }
        },
        "moduleName": "gemini/templates/components/content-manager.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "stripe-holder full-width");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "position: relative; top: -20px; left: 13px;");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "content", ["loc", [null, [2, 12], [2, 19]]]]], [], 0, null, ["loc", [null, [2, 4], [47, 13]]]], ["block", "paper-button", [], ["iconButton", true, "onClick", ["subexpr", "action", ["addContent", "end"], [], ["loc", [null, [50, 44], [50, 71]]]]], 1, null, ["loc", [null, [50, 4], [50, 117]]]], ["block", "if", [["get", "show-save-button", ["loc", [null, [52, 6], [52, 22]]]]], [], 2, null, ["loc", [null, [52, 0], [57, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});