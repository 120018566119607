define('gemini/helpers/fb-get', ['exports', 'ember', 'gemini/helpers/format-date', 'gemini/helpers/format-datetime'], function (exports, _ember, _geminiHelpersFormatDate, _geminiHelpersFormatDatetime) {
  exports.fbGet = fbGet;

  function fbGet(params) {
    var type = params[1].type;
    var value = params[0].get(params[1].name);

    if (type === undefined || type === "string") {
      return value;
    } else if (type === "date") {
      return _geminiHelpersFormatDate['default'].compute(value);
    } else if (type === "datetime") {
      console.log('fb-get', typeof value);
      return _geminiHelpersFormatDatetime['default'].compute(value);
    }
  }

  ;

  exports['default'] = _ember['default'].Helper.helper(fbGet);
});