define('gemini/models/file', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr("string"),

    active: _emberData['default'].attr('boolean'),
    createdAt: _emberData['default'].attr("date"),
    createdAtI: _emberData['default'].attr("number"), // inverse
    updatedAt: _emberData['default'].attr("date"),
    updatedAtI: _emberData['default'].attr("number"), // inverse

    paginationLink: "app.files.item"
  });
});