define("gemini/templates/app/push", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 12,
                        "column": 22
                      },
                      "end": {
                        "line": 12,
                        "column": 51
                      }
                    },
                    "moduleName": "gemini/templates/app/push.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Pushbericht");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 11,
                      "column": 18
                    },
                    "end": {
                      "line": 13,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/push.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "text.headline", [], [], 0, null, ["loc", [null, [12, 22], [12, 69]]]]],
                locals: ["text"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 10,
                    "column": 14
                  },
                  "end": {
                    "line": 14,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/push.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "title.text", [], [], 0, null, ["loc", [null, [11, 18], [13, 33]]]]],
              locals: ["title"],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 18
                    },
                    "end": {
                      "line": 27,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/push.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "layout", "row");
                  var el2 = dom.createTextNode("\n                          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "image-upload", [], ["flex", 100, "image-url", ["subexpr", "@mut", [["get", "image", ["loc", [null, [25, 60], [25, 65]]]]], [], []], "image-upload-array", ["subexpr", "@mut", [["get", "upload", ["loc", [null, [25, 85], [25, 91]]]]], [], []]], ["loc", [null, [25, 26], [25, 93]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 30,
                        "column": 22
                      },
                      "end": {
                        "line": 32,
                        "column": 22
                      }
                    },
                    "moduleName": "gemini/templates/app/push.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("strong");
                    var el2 = dom.createTextNode("Actie: ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    return morphs;
                  },
                  statements: [["content", "actionValue.value", ["loc", [null, [31, 41], [31, 62]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  var child0 = (function () {
                    var child0 = (function () {
                      var child0 = (function () {
                        return {
                          meta: {
                            "fragmentReason": false,
                            "revision": "Ember@2.4.6",
                            "loc": {
                              "source": null,
                              "start": {
                                "line": 36,
                                "column": 38
                              },
                              "end": {
                                "line": 38,
                                "column": 38
                              }
                            },
                            "moduleName": "gemini/templates/app/push.hbs"
                          },
                          isEmpty: false,
                          arity: 0,
                          cachedFragment: null,
                          hasRendered: false,
                          buildFragment: function buildFragment(dom) {
                            var el0 = dom.createDocumentFragment();
                            var el1 = dom.createTextNode("                                          ");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createComment("");
                            dom.appendChild(el0, el1);
                            var el1 = dom.createTextNode("\n");
                            dom.appendChild(el0, el1);
                            return el0;
                          },
                          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                            var morphs = new Array(1);
                            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                            return morphs;
                          },
                          statements: [["content", "newsitem.value", ["loc", [null, [37, 42], [37, 60]]]]],
                          locals: [],
                          templates: []
                        };
                      })();
                      return {
                        meta: {
                          "fragmentReason": false,
                          "revision": "Ember@2.4.6",
                          "loc": {
                            "source": null,
                            "start": {
                              "line": 35,
                              "column": 34
                            },
                            "end": {
                              "line": 39,
                              "column": 34
                            }
                          },
                          "moduleName": "gemini/templates/app/push.hbs"
                        },
                        isEmpty: false,
                        arity: 1,
                        cachedFragment: null,
                        hasRendered: false,
                        buildFragment: function buildFragment(dom) {
                          var el0 = dom.createDocumentFragment();
                          var el1 = dom.createComment("");
                          dom.appendChild(el0, el1);
                          return el0;
                        },
                        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                          var morphs = new Array(1);
                          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                          dom.insertBoundary(fragment, 0);
                          dom.insertBoundary(fragment, null);
                          return morphs;
                        },
                        statements: [["block", "paper-option", [], ["value", ["subexpr", "@mut", [["get", "newsitem.key", ["loc", [null, [36, 60], [36, 72]]]]], [], []]], 0, null, ["loc", [null, [36, 38], [38, 55]]]]],
                        locals: ["newsitem"],
                        templates: [child0]
                      };
                    })();
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.4.6",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 34,
                            "column": 30
                          },
                          "end": {
                            "line": 40,
                            "column": 30
                          }
                        },
                        "moduleName": "gemini/templates/app/push.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                        dom.insertBoundary(fragment, 0);
                        dom.insertBoundary(fragment, null);
                        return morphs;
                      },
                      statements: [["block", "each", [["get", "newsitems", ["loc", [null, [35, 42], [35, 51]]]]], [], 0, null, ["loc", [null, [35, 34], [39, 43]]]]],
                      locals: [],
                      templates: [child0]
                    };
                  })();
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.4.6",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 33,
                          "column": 26
                        },
                        "end": {
                          "line": 41,
                          "column": 26
                        }
                      },
                      "moduleName": "gemini/templates/app/push.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "paper-optgroup", [], ["label", "Nieuwsberichten"], 0, null, ["loc", [null, [34, 30], [40, 49]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 32,
                        "column": 22
                      },
                      "end": {
                        "line": 42,
                        "column": 22
                      }
                    },
                    "moduleName": "gemini/templates/app/push.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "paper-select", [], ["placeholder", "Actie", "value", ["subexpr", "@mut", [["get", "actionValue", ["loc", [null, [33, 68], [33, 79]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "actionValue", ["loc", [null, [33, 102], [33, 113]]]]], [], ["loc", [null, [33, 97], [33, 114]]]]], [], ["loc", [null, [33, 89], [33, 115]]]]], 0, null, ["loc", [null, [33, 26], [41, 43]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 18
                    },
                    "end": {
                      "line": 43,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/push.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "actionValue", ["loc", [null, [30, 28], [30, 39]]]]], [], 0, 1, ["loc", [null, [30, 22], [42, 29]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 14
                  },
                  "end": {
                    "line": 45,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/push.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "layout", "row");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "layout", "row");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                return morphs;
              },
              statements: [["inline", "paper-input", [], ["flex", 100, "label", "Titel", "value", ["subexpr", "@mut", [["get", "title", ["loc", [null, [17, 65], [17, 70]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "title", ["loc", [null, [17, 93], [17, 98]]]]], [], ["loc", [null, [17, 88], [17, 99]]]]], [], ["loc", [null, [17, 80], [17, 100]]]]], ["loc", [null, [17, 22], [17, 102]]]], ["inline", "paper-input", [], ["flex", 100, "textarea", true, "label", "Bericht", "value", ["subexpr", "@mut", [["get", "message", ["loc", [null, [20, 81], [20, 88]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "message", ["loc", [null, [20, 111], [20, 118]]]]], [], ["loc", [null, [20, 106], [20, 119]]]]], [], ["loc", [null, [20, 98], [20, 120]]]]], ["loc", [null, [20, 22], [20, 122]]]], ["block", "if", [["get", "addImage", ["loc", [null, [23, 24], [23, 32]]]]], [], 0, null, ["loc", [null, [23, 18], [27, 25]]]], ["block", "if", [["get", "addAction", ["loc", [null, [29, 24], [29, 33]]]]], [], 1, null, ["loc", [null, [29, 18], [43, 25]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 48,
                        "column": 22
                      },
                      "end": {
                        "line": 48,
                        "column": 108
                      }
                    },
                    "moduleName": "gemini/templates/app/push.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Afbeelding toevoegen");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 18
                    },
                    "end": {
                      "line": 49,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/push.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", [["subexpr", "mut", [["get", "addImage", ["loc", [null, [48, 71], [48, 79]]]], true], [], ["loc", [null, [48, 66], [48, 85]]]]], [], ["loc", [null, [48, 58], [48, 86]]]]], 0, null, ["loc", [null, [48, 22], [48, 125]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 51,
                        "column": 22
                      },
                      "end": {
                        "line": 51,
                        "column": 104
                      }
                    },
                    "moduleName": "gemini/templates/app/push.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Actie toevoegen");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 50,
                      "column": 18
                    },
                    "end": {
                      "line": 52,
                      "column": 18
                    }
                  },
                  "moduleName": "gemini/templates/app/push.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                      ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "paper-button", [], ["raised", true, "onClick", ["subexpr", "action", [["subexpr", "mut", [["get", "addAction", ["loc", [null, [51, 71], [51, 80]]]], true], [], ["loc", [null, [51, 66], [51, 86]]]]], [], ["loc", [null, [51, 58], [51, 87]]]]], 0, null, ["loc", [null, [51, 22], [51, 121]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 18
                    },
                    "end": {
                      "line": 53,
                      "column": 92
                    }
                  },
                  "moduleName": "gemini/templates/app/push.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Verstuur");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 14
                  },
                  "end": {
                    "line": 54,
                    "column": 14
                  }
                },
                "moduleName": "gemini/templates/app/push.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "unless", [["get", "addImage", ["loc", [null, [47, 28], [47, 36]]]]], [], 0, null, ["loc", [null, [47, 18], [49, 29]]]], ["block", "unless", [["get", "addAction", ["loc", [null, [50, 28], [50, 37]]]]], [], 1, null, ["loc", [null, [50, 18], [52, 29]]]], ["block", "paper-button", [], ["raised", true, "primary", true, "onClick", ["subexpr", "action", ["send"], [], ["loc", [null, [53, 67], [53, 82]]]]], 2, null, ["loc", [null, [53, 18], [53, 109]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 10
                },
                "end": {
                  "line": 55,
                  "column": 10
                }
              },
              "moduleName": "gemini/templates/app/push.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "card.title", [], [], 0, null, ["loc", [null, [10, 14], [14, 29]]]], ["block", "card.content", [], [], 1, null, ["loc", [null, [15, 14], [45, 31]]]], ["block", "card.actions", [], [], 2, null, ["loc", [null, [46, 14], [54, 31]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 6
              },
              "end": {
                "line": 56,
                "column": 6
              }
            },
            "moduleName": "gemini/templates/app/push.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "card.content", [], [], 0, null, ["loc", [null, [9, 10], [55, 27]]]]],
          locals: ["card"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 60,
              "column": 2
            }
          },
          "moduleName": "gemini/templates/app/push.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "paper-card", [], [], 0, null, ["loc", [null, [8, 6], [56, 21]]]], ["content", "outlet", ["loc", [null, [58, 6], [58, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 6
          }
        },
        "moduleName": "gemini/templates/app/push.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flex-100");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flex-gt-lg-70 flex-gt-md-80 flex-100");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "page-toolbar", [], ["pageTitle", ["subexpr", "t", ["push.in-toolbar"], [], ["loc", [null, [2, 27], [2, 48]]]]], ["loc", [null, [2, 2], [2, 50]]]], ["block", "paper-content", [], ["class", "md-padding"], 0, null, ["loc", [null, [5, 2], [60, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});