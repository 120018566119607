define('gemini/routes/app/logs', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      start: {
        refreshModel: true
      }
    },

    model: _ember['default'].observer("filterWarning", function (params) {
      // console.log("start-at", params["start"]);
      return this.store.query("log", { orderBy: 'createdAtI', limitToFirst: 200 });
      // return this.store.query("log", {orderBy: 'createdAtI', limitToFirst: 20, startAt: params["start"]});
    })
  });
});