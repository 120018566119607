define('gemini/components/ember-paper-wrapper', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  var inject = _ember['default'].inject;
  exports['default'] = _emberUploader['default'].FileField.extend({
    progress: 0,

    uploader: null,
    files: null,

    "upload-state": null,
    response: null,

    filesDidChange: function filesDidChange(files) {
      this.set('files', files);
    },

    setUploader: _ember['default'].on('init', function () {
      this.configUploader();
    }),

    configUploader: function configUploader() {
      var _this2 = this;

      console.log("url", this.get('url'));
      window.token = this.get('token');
      this.set('uploader', _emberUploader['default'].Uploader.create({
        url: this.get('url'),
        ajaxSettings: {
          headers: {
            'X-File-Access': 'private',
            'X-File-Name': this.get('file-name'),
            'X-Auth-Token': this.get('token')
          }
        }
      }));

      this.get('uploader').on('progress', function (e) {
        _this2.set("progress", e.percent);
      });

      this.get('uploader').on('didError', function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR, textStatus, errorThrown);
      });
    },

    tokenListener: _ember['default'].observer('token', "url", function () {
      this.configUploader();
    }),

    upload: _ember['default'].observer('upload-state', function () {
      var _this3 = this;

      console.log('uploadState changed', this.get('upload-state'));
      if (this.get('upload-state') === "upload") {
        (function () {
          var files = _this3.get('files');
          var _this = _this3;
          if (!_ember['default'].isEmpty(files)) {
            console.log('start upload');
            _this3.get('uploader').upload(files[0], {
              type: _this3.get("file-type")
            }).then(function (response) {
              console.log('set response', response);
              _this.set('response', response);
              _this.set('upload-state', 'ready');
              _this.set('files', null);
            }, function (e) {
              console.log('upload error', e);
              _this.set('upload-state', 'ready');
            });
          } else {
            console.log('empty files');
            _this.set('upload-state', 'ready');
          }
        })();
      }
    })
  });
});