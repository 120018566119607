define('gemini/helpers/format-date', ['exports', 'ember'], function (exports, _ember) {
  exports.formatDate = formatDate;

  function formatDate(value) {

    if (!value || typeof value === 'undefined') {
      return "";
    }

    if (typeof value[0] !== 'undefined') {
      value = value[0];
    }

    var d = new Date(value);
    if (isFinite(d)) {
      return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
    } else {
      return "";
    }
  }

  ;

  exports['default'] = _ember['default'].Helper.helper(formatDate);
});