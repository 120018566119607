define("gemini/controllers/app/faq/item", ["exports", "ember", "ember-i18n"], function (exports, _ember, _emberI18n) {
  exports["default"] = _ember["default"].Controller.extend({

    pageTitle: "Frequently Asked Questions / Item",
    i18n: _ember["default"].inject.service(),
    saveUpdate: "",

    saveTrigger: null,
    "is-saving": false, // used in UI
    "fb-is-saving": false, // saving firebase
    "cm-is-saving": false, // saving content manager

    actions: {
      save: function save() {
        var _this = this;
        this.set('is-saving', true);
        this.set('fb-is-saving', true);

        var model = this.get('model');
        model.setProperties({
          updatedAt: new Date(),
          updatedAtI: -new Date().getTime()
        });

        model.save().then(function () {
          _this.set('fb-is-saving', false);
        });

        this.set('saveTrigger', Math.random());
        // this.trigger('saveContent');
      },
      cancel: function cancel() {
        this.transitionToRoute('app.faq');
      },
      remove: function remove() {
        this.get('model').destroyRecord();
        this.send('cancel');
      },
      test: function test() {
        console.log(this.get('model.content'));
      },
      testIsSaving: function testIsSaving() {
        this.set('fb-is-saving', !this.get('fb-is-saving'));
      }
    },

    isSavingListener: _ember["default"].observer("fb-is-saving", "cm-is-saving", function () {
      console.log("is saving listener", this.get("cm-is-saving"), this.get("fb-is-saving"));
      if (!this.get("cm-is-saving") && !this.get("fb-is-saving")) {
        this.set("is-saving", false);
      } else {
        this.set('is-saving', true);
      }
    }),

    saveUpdateListener: _ember["default"].observer("is-saving", function () {
      var _this2 = this;

      if (this.get("is-saving")) {
        this.set("saveUpdate", (0, _emberI18n.translationMacro)("saving"));
      } else {
        (function () {
          _this2.set("saveUpdate", (0, _emberI18n.translationMacro)("saved"));
          var _this = _this2;
          _ember["default"].run.later(function () {
            _this.set('saveUpdate', '');
          }, 5000);
        })();
      }
    })
  });
});