define('gemini/controllers/app/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        ref: _ember['default'].inject.service('firebase'),

        windspeed: null,

        actions: {
            setWindSpeed: function setWindSpeed(windspeed) {
                var ref = this.get('ref');
                ref.child('/tmp/windspeed').set(windspeed);
            }
        },

        init: function init() {
            var _this = this;
            var ref = this.get('ref');

            ref.child('/tmp/windspeed').on("value", function (snapshot) {
                _this.setProperties({ windspeed: snapshot.val() });
            });

            console.log('currentRouteName', this.get('appCtrl'));
        }
    });
});