define('gemini/controllers/app/logs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    i18n: _ember['default'].inject.service(),
    sortedModel: _ember['default'].computed.sort('model', 'sorting'),
    sorting: ['createdAtI'],
    queryParams: ['start'],
    start: null,

    filterWarning: true,

    filteredModel: _ember['default'].computed('sortedModel', 'filterWarning', function () {
      var filterWarning = this.get('filterWarning');
      return this.get('sortedModel').filter(function (log) {
        if (!filterWarning) {
          return true;
        } else {
          return log.get('level') === 'warning';
        }
      });
    }),

    columns: []

  });
});
// columnsSet: Ember.on('init', function(){
//   this.set('columns.0.i18n',this.get('i18n'));
//   this.set('columns.0.label', t('logs.time'));
//   this.set('columns.1.i18n',this.get('i18n'));
//   this.set('columns.1.label', t('logs.source'));
//   this.set('columns.2.i18n',this.get('i18n'));
//   this.set('columns.2.label', t('logs.action'));
// }),