define("gemini/templates/app/info", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 10,
                    "column": 8
                  },
                  "end": {
                    "line": 11,
                    "column": 78
                  }
                },
                "moduleName": "gemini/templates/app/info.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["info.short"], [], ["loc", [null, [11, 60], [11, 78]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 8
                  },
                  "end": {
                    "line": 13,
                    "column": 88
                  }
                },
                "moduleName": "gemini/templates/app/info.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["info.detailed"], [], ["loc", [null, [13, 67], [13, 88]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 6
                },
                "end": {
                  "line": 14,
                  "column": 6
                }
              },
              "moduleName": "gemini/templates/app/info.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["block", "paper-button", [], ["raised", ["subexpr", "eq", [["get", "showField", ["loc", [null, [10, 35], [10, 44]]]], "wall"], [], ["loc", [null, [10, 31], [10, 52]]]], "primary", ["subexpr", "eq", [["get", "showField", ["loc", [null, [10, 65], [10, 74]]]], "wall"], [], ["loc", [null, [10, 61], [10, 82]]]], "onClick", ["subexpr", "action", ["setField", "wall"], [], ["loc", [null, [11, 32], [11, 58]]]]], 0, null, ["loc", [null, [10, 8], [11, 95]]]], ["block", "paper-button", [], ["raised", ["subexpr", "eq", [["get", "showField", ["loc", [null, [12, 35], [12, 44]]]], "information"], [], ["loc", [null, [12, 31], [12, 59]]]], "primary", ["subexpr", "eq", [["get", "showField", ["loc", [null, [12, 72], [12, 81]]]], "information"], [], ["loc", [null, [12, 68], [12, 96]]]], "onClick", ["subexpr", "action", ["setField", "information"], [], ["loc", [null, [13, 32], [13, 65]]]]], 1, null, ["loc", [null, [12, 8], [13, 105]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.6",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 27,
                        "column": 12
                      },
                      "end": {
                        "line": 27,
                        "column": 94
                      }
                    },
                    "moduleName": "gemini/templates/app/info.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["inline", "t", ["save"], [], ["loc", [null, [27, 82], [27, 94]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 25,
                      "column": 10
                    },
                    "end": {
                      "line": 28,
                      "column": 10
                    }
                  },
                  "moduleName": "gemini/templates/app/info.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "save-update flex");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  return morphs;
                },
                statements: [["content", "saveUpdate", ["loc", [null, [26, 43], [26, 57]]]], ["block", "paper-button", [], ["raised", true, "primary", true, "onClick", ["subexpr", "action", ["saveText"], [], ["loc", [null, [27, 61], [27, 80]]]]], 0, null, ["loc", [null, [27, 12], [27, 111]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 8
                  },
                  "end": {
                    "line": 29,
                    "column": 8
                  }
                },
                "moduleName": "gemini/templates/app/info.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "layout-column");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "paper-input", [], ["flex", 100, "textarea", true, "label", ["subexpr", "t", ["info.short-description"], [], ["loc", [null, [19, 55], [19, 83]]]], "value", ["subexpr", "@mut", [["get", "infotextwall", ["loc", [null, [19, 90], [19, 102]]]]], [], []], "onChange", ["subexpr", "action", [["subexpr", "mut", [["get", "infotextwall", ["loc", [null, [20, 48], [20, 60]]]]], [], ["loc", [null, [20, 43], [20, 61]]]]], [], ["loc", [null, [20, 35], [20, 62]]]]], ["loc", [null, [19, 12], [20, 64]]]], ["inline", "image-upload", [], ["url", "https://gemini-back-end.livemegawatt.com/file-upload", "image-url", ["subexpr", "@mut", [["get", "infowallimage", ["loc", [null, [23, 94], [23, 107]]]]], [], []], "image-width", ["subexpr", "@mut", [["get", "infowallimagewidth", ["loc", [null, [23, 120], [23, 138]]]]], [], []], "image-height", ["subexpr", "@mut", [["get", "infowallimageheight", ["loc", [null, [23, 152], [23, 171]]]]], [], []], "firebase-end-point", "info/wall/about/image-url", "image-upload-array", ["subexpr", "@mut", [["get", "image-uploads", ["loc", [null, [24, 91], [24, 104]]]]], [], []]], ["loc", [null, [23, 10], [24, 106]]]], ["block", "card.actions", [], [], 0, null, ["loc", [null, [25, 10], [28, 27]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 6
                },
                "end": {
                  "line": 31,
                  "column": 6
                }
              },
              "moduleName": "gemini/templates/app/info.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "card.content", [], [], 0, null, ["loc", [null, [17, 8], [29, 25]]]], ["inline", "optional-loader", [], ["loading", ["subexpr", "@mut", [["get", "is-saving", ["loc", [null, [30, 34], [30, 43]]]]], [], []]], ["loc", [null, [30, 8], [30, 45]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 6
                },
                "end": {
                  "line": 38,
                  "column": 6
                }
              },
              "moduleName": "gemini/templates/app/info.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              return morphs;
            },
            statements: [["inline", "content-manager", [], ["firebase-end-point", "info/about/content", "onSavingChanged", ["subexpr", "action", [["subexpr", "mut", [["get", "cm-is-saving", ["loc", [null, [36, 95], [36, 107]]]]], [], ["loc", [null, [36, 90], [36, 108]]]]], [], ["loc", [null, [36, 82], [36, 109]]]]], ["loc", [null, [36, 8], [36, 111]]]], ["inline", "optional-loader", [], ["loading", ["subexpr", "@mut", [["get", "is-saving", ["loc", [null, [37, 34], [37, 43]]]]], [], []]], ["loc", [null, [37, 8], [37, 45]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 4
              },
              "end": {
                "line": 39,
                "column": 4
              }
            },
            "moduleName": "gemini/templates/app/info.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "card.title", [], [], 0, null, ["loc", [null, [9, 6], [14, 21]]]], ["block", "if", [["subexpr", "eq", [["get", "showField", ["loc", [null, [16, 16], [16, 25]]]], "wall"], [], ["loc", [null, [16, 12], [16, 33]]]]], [], 1, null, ["loc", [null, [16, 6], [31, 13]]]], ["block", "if", [["subexpr", "eq", [["get", "showField", ["loc", [null, [34, 16], [34, 25]]]], "information"], [], ["loc", [null, [34, 12], [34, 40]]]]], [], 2, null, ["loc", [null, [34, 6], [38, 13]]]]],
          locals: ["card"],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.6",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 10
                    },
                    "end": {
                      "line": 44,
                      "column": 36
                    }
                  },
                  "moduleName": "gemini/templates/app/info.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("Feedback");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.6",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 8
                  },
                  "end": {
                    "line": 45,
                    "column": 8
                  }
                },
                "moduleName": "gemini/templates/app/info.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "text.headline", [], [], 0, null, ["loc", [null, [44, 10], [44, 54]]]]],
              locals: ["text"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 6
                },
                "end": {
                  "line": 46,
                  "column": 6
                }
              },
              "moduleName": "gemini/templates/app/info.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "title.text", [], [], 0, null, ["loc", [null, [43, 8], [45, 23]]]]],
            locals: ["title"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 4
              },
              "end": {
                "line": 48,
                "column": 4
              }
            },
            "moduleName": "gemini/templates/app/info.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "card.title", [], [], 0, null, ["loc", [null, [42, 6], [46, 21]]]], ["inline", "feedback-manager", [], ["card", ["subexpr", "@mut", [["get", "card", ["loc", [null, [47, 30], [47, 34]]]]], [], []], "firebase-url", "feedback/about"], ["loc", [null, [47, 6], [47, 66]]]]],
          locals: ["card"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 2
            }
          },
          "moduleName": "gemini/templates/app/info.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "paper-card", [], [], 0, null, ["loc", [null, [7, 4], [39, 19]]]], ["block", "paper-card", [], [], 1, null, ["loc", [null, [41, 4], [48, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 6
          }
        },
        "moduleName": "gemini/templates/app/info.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flex-100");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "flex-gt-lg-70 flex-gt-md-80 flex-100");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "page-toolbar", [], ["pageTitle", ["subexpr", "t", ["info.in-toolbar"], [], ["loc", [null, [2, 27], [2, 48]]]]], ["loc", [null, [2, 2], [2, 50]]]], ["block", "paper-content", [], ["class", "md-padding"], 0, null, ["loc", [null, [5, 2], [50, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});