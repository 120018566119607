define('gemini/helpers/device-model-name', ['exports', 'ember', 'npm:ios-device-list'], function (exports, _ember, _npmIosDeviceList) {
  exports.deviceModelName = deviceModelName;

  function deviceModelName(params) {
    var identifier = params[0];
    if (typeof identifier !== "string") return "";
    var iosModel = _npmIosDeviceList['default'].generationByIdentifier(identifier);
    return iosModel !== undefined ? iosModel : identifier;
  }

  exports['default'] = _ember['default'].Helper.helper(deviceModelName);
});