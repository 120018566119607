define('gemini/controllers/app/info', ['exports', 'ember', 'ember-i18n'], function (exports, _ember, _emberI18n) {
    exports['default'] = _ember['default'].Controller.extend({

        ref: _ember['default'].inject.service('firebase'),
        i18n: _ember['default'].inject.service(),

        infotitle: null,
        infotextwall: null,
        infowallimage: null,
        infowallimagewidth: null,
        infowallimageheight: null,
        infotext: null,
        info: null,

        "image-uploads": [],

        wallimageloaded: false,

        showField: "wall",

        actions: {
            saveText: function saveText() {

                this.set("is-saving", true);

                var uploadPromises = [];
                this.get('image-uploads').forEach(function (imageUpload) {
                    uploadPromises.push(imageUpload.upload());
                });
                var _this = this;
                var ref = _this.get('ref');
                console.log(uploadPromises);
                _ember['default'].RSVP.Promise.all(uploadPromises).then(function (value) {
                    console.log(value);
                    ref.child('info/wall/about/title').set(_this.get('infotitle'));
                    ref.child('info/wall/about/text').set(_this.get('infotextwall'));
                    ref.child('info/wall/about/image-url').set(_this.get('infowallimage'));
                    ref.child('info/wall/about/image-width').set(_this.get('infowallimagewidth'));
                    ref.child('info/wall/about/image-height').set(_this.get('infowallimageheight'));

                    console.log('width: ' + _this.get('infowallimagewidth'));

                    _this.set("is-saving", false);
                });
            },
            setField: function setField(field) {
                this.set("showField", field);
            }
        },
        saveUpdateListener: _ember['default'].observer("is-saving", function () {
            var _this2 = this;

            if (this.get("is-saving")) {
                this.set("saveUpdate", (0, _emberI18n.translationMacro)("saving"));
            } else {
                (function () {
                    _this2.set("saveUpdate", (0, _emberI18n.translationMacro)("saved"));
                    var _this = _this2;
                    _ember['default'].run.later(function () {
                        _this.set('saveUpdate', '');
                    }, 5000);
                })();
            }
        }),

        init: function init() {
            var _this = this;
            var ref = this.get('ref');

            ref.child('info/wall/about').on("value", function (snapshot) {
                _this.setProperties({ infotextwall: snapshot.child('text').val() });
                _this.setProperties({ infotitle: snapshot.child('title').val() });
                _this.setProperties({ infowallimage: snapshot.child('image-url').val() });
                _this.setProperties({ infowallimagewidth: snapshot.child('image-width').val() });
                _this.setProperties({ infowallimageheight: snapshot.child('image-height').val() });
            });
        }
    });
});